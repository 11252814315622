import './font/PPMori/fonts.css';

import {CodeIcon} from '@sharefiledev/flow-web';
import {projectsExtensionSlots} from '@sharefiledev/projects-pilet';
import type {PiletApi} from '@sharefiledev/sharefile-appshell';
import {Skeleton, Spin} from 'antd';
import React from 'react';

import {defaultDataProvider, useSpecEditDataProvider} from './api';
import {BLOCKS} from './Blocks/';
import {
    AsyncCreateInformationRequestDrawerBlank,
    AsyncInformationRequestListBlock,
    AsyncRenderClientShareLinkFormBlock,
    AsyncRenderInfReqGrantAccessListBlock,
} from './components/Blocks';
import type {
    ClientShareLinkFormParams,
    CommonExtensionProps,
    InformationRequestListBlockParams,
    InfReqGrantAccessListParams,
} from './components/Blocks/types';
import {PageProps} from './components/common/types';
import {ClientViewExtensionProps} from './components/Extensions/ClientViewExtension';
import {DeletedResourcesExtensionProps} from './components/Extensions/DeletedResourcesExtension';
import {TabsExtensionProps} from './components/Extensions/TabsExtension';
import {shortWebRequestURISegment} from './config/constants';
import {
    AsyncRequestClientView,
    AsyncRequestViewDrawer,
    AsyncShareButtonExtensionSlot,
} from './Extensions';
import {setLogger} from './logger';
import {
    InformationRequestContentParams,
    informationRequestPiletExtensionSlots,
    piletOverlays,
    SpecEditorExtensionParams,
} from './sdk/extensionTypes';

const LazyBuilderPage = React.lazy(() => import('./components/Extensions/BuilderPage'));
const AsyncLazyBuilderPage: React.FC<PageProps> = (props) => {
    return (
        <React.Suspense fallback={<Skeleton />}>
            <LazyBuilderPage {...props} />
        </React.Suspense>
    );
};

const LazyFormPage = React.lazy(() => import('./components/Extensions/FormPage'));
const AsyncLazyFormPage: React.FC<PageProps> = (props) => {
    return (
        <React.Suspense fallback={<Skeleton />}>
            <LazyFormPage {...props} />
        </React.Suspense>
    );
};

const LazyInformationRequestPage = React.lazy(
    () => import('./components/Extensions/InformationRequestPage'),
);
const AsyncLazyInformationRequestPage: React.FC<PageProps> = (props) => {
    return (
        <React.Suspense fallback={<Skeleton />}>
            <LazyInformationRequestPage {...props} />
        </React.Suspense>
    );
};

const LazySpecEditorExtension = React.lazy(
    () => import('./components/Extensions/SpecEditorExtension'),
);
const AsyncLazySpecEditor: React.FC<{piletApi: PiletApi} & SpecEditorExtensionParams> = (props) => {
    const dataProvider = useSpecEditDataProvider(props.resourceSpec, props.onUpdate);

    return (
        <React.Suspense fallback={<Skeleton />}>
            <LazySpecEditorExtension {...props} dataProvider={dataProvider} />
        </React.Suspense>
    );
};

const LazyTabsExtension = React.lazy(() => import('./components/Extensions/TabsExtension'));
const AsyncLazyTabsExtension: React.FC<TabsExtensionProps> = (props) => {
    return (
        <React.Suspense fallback={<Skeleton />}>
            <LazyTabsExtension {...props} />
        </React.Suspense>
    );
};

const LazyClientView = React.lazy(() => import('./components/Extensions/ClientViewExtension'));
const AsyncLazyClientView: React.FC<ClientViewExtensionProps> = (props) => {
    return (
        <React.Suspense fallback={<Skeleton />}>
            <LazyClientView {...props} />
        </React.Suspense>
    );
};

const LazyDeletedResourcesExtension = React.lazy(
    () => import('./components/Extensions/DeletedResourcesExtension'),
);
const AsyncLazyDeletedResourcesExtension: React.FC<DeletedResourcesExtensionProps> = (props) => {
    return (
        <React.Suspense fallback={<Skeleton />}>
            <LazyDeletedResourcesExtension {...props} />
        </React.Suspense>
    );
};

const LazyDevPage = React.lazy(() => import('./components/Extensions/DevPage'));
const AsyncLazyDevPage: React.FC<PageProps> = (props) => {
    return (
        <React.Suspense fallback={<Skeleton />}>
            <LazyDevPage {...props} />
        </React.Suspense>
    );
};

const LazyBlockDevelopment = React.lazy(() => import('./Development/BlockDevelopment'));
const AsyncLazyBlockDevelopment: React.FC<CommonExtensionProps> = (props) => {
    return (
        <React.Suspense fallback={<Spin />}>
            <LazyBlockDevelopment {...props} />
        </React.Suspense>
    );
};

export const setup = (app: PiletApi) => {
    setLogger(app.sf.getLogger());

    registerExtensions(app);

    app.registerPage(
        `/${shortWebRequestURISegment}/:informationRequestId/builder`,
        () => (<AsyncLazyBuilderPage piletApi={app} />) as any,
    );

    // NOTE: ensure this is registered before the information request view
    // NOTE: replace with constant later
    app.registerPage(
        `/${shortWebRequestURISegment}/:informationRequestId/form`,
        () => (<AsyncLazyFormPage piletApi={app} />) as any,
    );

    app.registerPage(
        `/${shortWebRequestURISegment}/:informationRequestId`,
        () => (<AsyncLazyInformationRequestPage piletApi={app} />) as any,
    );

    app.registerDrawer(
        piletOverlays.informationRequest,
        ({options, cancelInteraction, completeInteraction}) => (
            <AsyncRequestViewDrawer
                piletApi={app}
                completeInteraction={completeInteraction}
                cancelInteraction={cancelInteraction}
                {...options!}
            />
        ),
        {
            drawerProps: {
                title: 'Information request',
                width: '100%',
                placement: 'right',
                size: 'large',
                zIndex: 9,
            },
        },
    );

    app.registerDrawer(
        piletOverlays.clientInformationRequest,
        ({options, cancelInteraction, completeInteraction, updateDrawerProps}) => (
            <AsyncRequestClientView
                piletApi={app}
                containerRID={options?.params.containerRID || ''}
                completeInteraction={completeInteraction}
                cancelInteraction={cancelInteraction}
                updateDrawerProps={updateDrawerProps}
                {...options!}
            />
        ),
        {
            drawerProps: {
                title: 'Information request',
                width: 640,
                placement: 'right',
                size: 'large',
                zIndex: 9,
            },
        },
    );

    app.registerDrawer(
        piletOverlays.createInformationRequestFromBlank,
        ({options, setWidth, completeInteraction, cancelInteraction}) => {
            return (
                <AsyncCreateInformationRequestDrawerBlank
                    piletApi={app}
                    containerRID={options?.params.containerRID || ''}
                    setWidth={setWidth}
                    originAnalyticsTrackingId={options?.params?.originAnalyticsTrackingId || ''}
                    completeInteraction={completeInteraction}
                    cancelInteraction={cancelInteraction}
                    showCloseButton={options?.params?.showCloseButton}
                />
            );
        },
        {drawerProps: {styles: {body: {padding: 0}}, width: '500px', zIndex: 9}},
    );

    app.sf.registerViewEngineResourceURLRewrite({
        resourceType: 'inforeq',
        defaultPath: '/inforeq/{informationRequestRID}',
        callback: async (rid: string) => {
            return await defaultDataProvider.getInformationRequest(rid);
        },
    });

    if (process.env.NODE_ENV === 'development') {
        app.sf.registerLeftNavComponent({
            href: '/forms-dev',
            title: () => 'Forms Development',
            icon: () => null,
        });

        app.registerPage('/forms-dev', () => (<AsyncLazyDevPage piletApi={app} />) as any);

        app.registerPage('/information-request-block-dev', () => (
            <AsyncLazyBlockDevelopment piletApi={app} />
        ));
        app.sf.registerLeftNavComponent({
            href: '/information-request-block-dev',
            title: () => 'Forms Block Dev',
            icon: () => <CodeIcon />,
        });
    }
};

const registerExtensions = (app: PiletApi) => {
    app.registerExtension(
        projectsExtensionSlots.tabs,
        ({params}) =>
            (
                <AsyncLazyTabsExtension
                    projectUri={params.projectUri}
                    piletApi={app}
                    tabContext={params.tabContext}
                />
            ) as any,
        {piletSortWeight: 100},
    );

    app.registerExtension(
        informationRequestPiletExtensionSlots.projectTab,
        ({params}) =>
            (
                <AsyncLazyTabsExtension
                    projectUri={params.projectUri}
                    piletApi={app}
                    tabContext={params.tabContext}
                    isFrozen={params.isFrozen}
                />
            ) as any,
        {piletSortWeight: 100},
    );

    app.registerExtension<InformationRequestContentParams>(
        informationRequestPiletExtensionSlots.informationRequestContent,
        ({params}) => {
            return (
                <AsyncLazyClientView
                    piletApi={app}
                    containerRID={params.containerRID}
                    submitHandler={params.submitHandler}
                />
            );
        },
    );

    // Solution UI tab registration
    app.registerExtension<InformationRequestListBlockParams>(
        BLOCKS.INFORMATION_REQUEST_LIST,
        ({params}) => <AsyncInformationRequestListBlock piletApi={app} {...params} />,
    );

    // Sharable link form registration
    app.registerExtension<ClientShareLinkFormParams>(BLOCKS.CLIENT_SHARE_LINK_FORM, ({params}) => (
        <AsyncRenderClientShareLinkFormBlock piletApi={app} {...params} />
    ));

    // Sharable link form grant access list registration
    app.registerExtension<InfReqGrantAccessListParams>(
        BLOCKS.INFORMATION_REQUEST_GRANT_ACCESS_LIST,
        ({params}) => <AsyncRenderInfReqGrantAccessListBlock piletApi={app} {...params} />,
    );

    // Restore tab registration
    app.registerExtension(
        // 'urn:sfextslot:projects-pilet:information-request:recentlydeleted',
        projectsExtensionSlots.deletedResources,
        ({params}) =>
            (
                <AsyncLazyDeletedResourcesExtension
                    piletApi={app}
                    tabContext={params.tabContext}
                    projectUri={params.projectUri}
                />
            ) as any,
    );

    app.registerExtension(informationRequestPiletExtensionSlots.shareButton, ({params}) => {
        return <AsyncShareButtonExtensionSlot piletApi={app} {...params} />;
    });

    app.registerExtension(informationRequestPiletExtensionSlots.specEditor, ({params}) => {
        return <AsyncLazySpecEditor piletApi={app} {...params} />;
    });
};
